<template>
  <div class="mt-10 px-4">
    <!-- background -->
    <div class="background-ball-big"></div>
    <div class="background-ball-small"></div>

    <div class="pt-4 title">
      <h3>Create Account</h3>
    </div>

    <!-- loading -->
    <div v-if="layouts.actives.loading">loading</div>
    <!-- input pwd -->
    <template v-else>
      <wap-text-field
        label="Email"
        disabled
        v-model="dataSources.current.email"
      >
      </wap-text-field>
      <wap-text-field
        label="Enter Password"
        :append-icon="layouts.actives.showPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="layouts.actives.showPwd ? 'text' : 'password'"
        v-model="dataSources.current.password"
        @click:append="layouts.actives.showPwd = !layouts.actives.showPwd"
        @change="layouts.actives.alert = false"
      >
      </wap-text-field>
      <wap-text-field
        label="Confirm Password"
        :append-icon="layouts.actives.showConfirmPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="layouts.actives.showConfirmPwd ? 'text' : 'password'"
        v-model="dataSources.current.confirmPassword"
        @click:append="layouts.actives.showConfirmPwd = !layouts.actives.showConfirmPwd"
        @change="layouts.actives.alert = false"
      >
      </wap-text-field>

      <v-card flat height="240" class="pt-2">
        <check-password-strength
          v-model="dataSources.current.password"
          :confirmPassword="dataSources.current.confirmPassword"
          @strengthResult="updateStrengthResult"
        ></check-password-strength>
      </v-card>

      <wap-btn
        class="width-full"
        :disabledControl="!(dataSources.current.passwordStrength
        && dataSources.current.password === dataSources.current.confirmPassword
        && assists.tool.isNotEmpty(dataSources.current.password)
        && assists.tool.isNotEmpty(dataSources.current.confirmPassword)
        )"
        @click="clickCreateAccount">
        Create Account
      </wap-btn>

      <div class="d-flex align-center my-8">
        <v-divider></v-divider>
        <span class="px-4">Or</span>
        <v-divider></v-divider>
      </div>

      <div class="text-center">
        Already have an account? <a class="f-w-600" @click="$router.push('/sign-in')">Sign In</a>
      </div>
    </template>
  </div>
</template>

<script>
import WapTextField from '@/components/base/WapTextField'
import WapBtn from '../../components/base/WapBtn.vue'
import { Tool, Crypto } from '@/assets/js/util'
import { AccountApi } from '@/api'
import CheckPasswordStrength from '@/components/common/CheckPasswordStrength'

export default {
  components: { WapTextField, WapBtn, CheckPasswordStrength },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        current: {
          email: '',
          password: '',
          confirmPassword: '',
          passwordStrength: false
        }
      },
      layouts: {
        actives: {
          loading: false,
          alert: false,
          showPwd: false,
          showConfirmPwd: false
        }
      }
    }
  },
  created () {
    const routeParam = this.$route.path
    // 暂存路径，方便域名切换时跳转
    this.$store.commit('setRedirectUrlParameter', routeParam)

    const resource = this.$route.params?.resource
    if (this.assists.tool.isNotEmpty(resource)) {
      AccountApi.parseLinkResource(resource, (res) => {
        this.dataSources.current.email = res.email
      }, (err) => {
        this.assists.tool.setPopupInformation(err.message, 'error')
      })
    }
  },
  methods: {
    updateStrengthResult (result) {
      this.dataSources.current.passwordStrength = result
    },
    clickCreateAccount () {
      const { email, password } = this.dataSources.current

      if (this.assists.tool.isEmpty(email)) {
        this.layouts.actives.alert = true
        this.$store.commit('setPopupInformation', {
          message: 'Email is required.'
        })
        return
      }

      AccountApi.createAccount(
        {
          resource: this.$route.params?.resource,
          password: Crypto.md5(password)
        },
        () => {
          this.assists.tool.setPopupInformation('Congratulations, your account has been created successfully!', 'success')
          setTimeout(() => {
            this.$router.push('/sign-in')
          }, 2000)
        },
        (err) => {
          this.layouts.actives.alert = true
          this.dataSources.current.errorMsg = err.message
        })
    }
  }
}
</script>

<style lang='sass' scoped>
.background-ball-big
  position: absolute
  top: -60vw
  left: -5vw
  width: 110vw
  height: 110vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.background-ball-small
  position: absolute
  top: -55vw
  right: -58vw
  width: 100vw
  height: 100vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.title
  height: 18vh

.check-text
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 90%
  font-size: 17px
</style>
